import React, { useState, useEffect } from "react";
import { Row, Col, Collapse, Button } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import colombinaLogo from "../../assets/LOGO_COLOMBINA.png";
import colombinaLogoENG from "../../assets/LOGO_COL_ENG.png";

import homeButtonEs from "../../assets/INICIO-BLANCO.png";
import homeButtonEn from "../../assets/HOME-BLANCO.png";

import eng from "../../assets/eng.png";

import BARA_VERTICAL from "../../assets/BARA_VERTICAL.png";
import { MdVolumeUp } from "react-icons/md";
import market from "../../assets/LOGO_MARKET_COLOMBINA.png";
import { MdVolumeOff } from "react-icons/md";

import { LOCALES } from "../../i8n/locales";

import RappiLogo from "../../assets/2024/navbar/BOTON-MENU-RAPPI.png";
import { useAudio } from "../../hooks/useAudio";

import eng_selected from "../../assets/ENGLISH-DORADO.png";
import spanish from "../../assets/SPANISH-BLANCO.png";
import spanishSelected from "../../assets/esp_yellow.png";
export const NavBar = ({
  audio,
  setLanguage,
  lang,
  playingPar,
  setPlayingPar,
}) => {
  const [open, setOpen] = useState(false);

  const [playing, toggle] = useAudio(audio, playingPar, setPlayingPar);

  const paises = [
    "BOLIVIA",
    "ECUADOR",
    "PERU",
    "VENEZUELA",
    "COLOMBIA",
    "COSTA_RICA",
    "SALVADOR",
    "GUATEMALA",
    "HONDURAS",
    "PANAMA",
    "GUYANAS",
    "JAMAICA",
    "SURINAM",
    "TRINIDAD_TOBAGO",
    "REPUBLICA_DOMINICANA",
    "PUERTO_RICO",
    "USA",
  ];

  //console.log(paises.includes(pais) ? "estoy en los paises" : "noestoy");

  const path = useLocation().pathname;
  const donde = path.split("/")[1];
  const green = ["fun"];
  const isGreen = green.some((word) => path.includes(word));
  return (
    <div>
      <Row
        className={`navbar align-items-center ${
          isGreen ? "bg__greenNav" : "bg__redNav"
        }`}
        id={
          path.includes("fun") ||
          path.includes("recipes") ||
          path.includes("christmasCard") ||
          path.includes("christmascard")
            ? "transparent"
            : ""
        }
      >
        <Col xs={2} className="text-left navbar__col">
          <Link to={"/" + donde}>
            <img
              alt="logo"
              style={{ width: "140px" }}
              src={lang.includes("es") ? colombinaLogo : colombinaLogoENG}
            ></img>
          </Link>
        </Col>

        <Col
          xs={4}
          className="text-left navbar__col d-flex justify-content-center align-items-center navbar__gap"
        >
          <Link to={"/"}>
            <img
              alt="home"
              style={{ width: "120px" }}
              src={lang.includes("es") ? homeButtonEs : homeButtonEn}
            ></img>
          </Link>
          <span>
            {" "}
            <img
              src={BARA_VERTICAL}
              alt="market"
              width="2rem"
              height="30px"
            ></img>{" "}
          </span>
          <button
            className="botonIdiomas"
            onClick={() => {
              setLanguage(LOCALES.ENGLISH);
            }}
          >
            <img
              src={lang === LOCALES.ENGLISH ? eng_selected : eng}
              alt="market"
              width="140px"
              height="auto"
            ></img>
          </button>
          <span>
            {" "}
            <img
              src={BARA_VERTICAL}
              alt="market"
              width="2rem"
              height="30px"
            ></img>{" "}
          </span>
          <button
            className="botonIdiomas"
            onClick={() => {
              setLanguage(LOCALES.SPANISH);
            }}
          >
            <img
              src={lang === LOCALES.SPANISH ? spanishSelected : spanish}
              alt="market"
              width="140px"
              height="auto"
            ></img>
          </button>
        </Col>
        {/* <FormattedMessage id="lan" /> */}

        <Col
          xs={3}
          className="text-center navbar__col d-flex justify-content-center align-items-center navbar__gap"
        >
          {path.includes("COLOMBIA") && (
            <>
              <a
                href={
                  "https://www.rappi.com.co/tiendas/900266484-colombinamarket-brand-nc"
                }
                target="__blank"
              >
                {" "}
                <img
                  src={RappiLogo}
                  alt="market"
                  width="40px"
                  height="40px"
                  style={{ marginLeft: "10px" }}
                ></img>
              </a>
              <span>
                {" "}
                <img
                  src={BARA_VERTICAL}
                  alt="market"
                  width="2rem"
                  height="30px"
                ></img>{" "}
              </span>
            </>
          )}

          {donde ? (
            <Link to="/">
              <img
                src={
                  paises.includes(donde)
                    ? require("../../assets/" + donde + ".png")
                    : require("../../assets/COLOMBIA.png")
                }
                alt="market"
                width="40px"
                height="40px"
                style={{ marginLeft: "10px" }}
              ></img>
            </Link>
          ) : (
            <></>
          )}

          <Button className="botonIdiomas"></Button>

          <button
            className="botonSonido"
            onClick={toggle}
            style={{ padding: 0 }}
          >
            {playing ? <MdVolumeUp /> : <MdVolumeOff />}
          </button>
        </Col>
      </Row>
      <Row
        className={`navbar__responsive ${
          isGreen ? "bg__greenNav" : "bg__redNav"
        }`}
      >
        <Row className="rowArriba justify-content-around">
          <Col
            xs={2}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {donde ? (
              <Link to="/">
                <img
                  src={
                    paises.includes(donde)
                      ? require("../../assets/" + donde + ".png")
                      : require("../../assets/COLOMBIA.png")
                  }
                  alt="market"
                  width="40px"
                  height="40px"
                  style={{ marginLeft: "10px" }}
                ></img>
              </Link>
            ) : (
              <></>
            )}
          </Col>
          <Col xs={4}>
            <Link to={"/" + donde} style={{ width: "fit-content" }}>
              <img
                className="w-100 logo__colombina"
                src={lang.includes("es") ? colombinaLogo : colombinaLogoENG}
              ></img>
            </Link>
          </Col>

          <Col
            xs={2}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <button
              className="botonSonido"
              onClick={toggle}
              style={{ padding: 0 }}
            >
              {playing ? <MdVolumeUp /> : <MdVolumeOff />}
            </button>
          </Col>
          <Col
            xs={2}
            className="text-left navbar__col__toggle navbar__responsive__col"
            id="margenArriba"
          >
            <button
              className="navbar-toggler"
              type="button"
              onClick={() => setOpen(!open)}
              aria-controls="navbarCollapse"
              aria-expanded={open}
            >
              <span className="navbar-toggler-icon"></span>
            </button>
          </Col>
        </Row>

        <Collapse in={open}>
          <div
            id="navbarCollapse"
            className={` ${isGreen ? "bg__greenNav" : "bg__redNav"}`}
          >
            <Row>
              <Col
                xs={12}
                lg={1}
                className="text-center navbar__col"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <button
                  className="botonIdiomas"
                  onClick={() => {
                    setLanguage(LOCALES.ENGLISH);
                  }}
                >
                  <img
                    src={lang === LOCALES.ENGLISH ? eng_selected : eng}
                    alt="market"
                    width="70px"
                    height="20px"
                  ></img>
                </button>
                <span>
                  <img
                    src={BARA_VERTICAL}
                    alt="market"
                    width="auto"
                    height="30px"
                  ></img>
                </span>
                <button
                  className="botonIdiomas"
                  onClick={() => {
                    setLanguage(LOCALES.SPANISH);
                  }}
                >
                  <img
                    src={lang === LOCALES.SPANISH ? spanishSelected : spanish}
                    alt="market"
                    width="70px"
                    height="20px"
                  ></img>
                </button>
              </Col>
            </Row>

            {donde === "COLOMBIA" ? (
              <Row>
                {path.includes("COLOMBIA") && (
                  <>
                    <a
                      href={
                        "https://www.rappi.com.co/tiendas/900266484-colombinamarket-brand-nc"
                      }
                      target="__blank"
                    >
                      {" "}
                      <img
                        src={RappiLogo}
                        alt="market"
                        width="40px"
                        height="40px"
                        style={{ marginLeft: "10px" }}
                      ></img>
                    </a>
                  </>
                )}
              </Row>
            ) : (
              <></>
            )}
          </div>
        </Collapse>
      </Row>
    </div>
  );
};
