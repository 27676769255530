import React, { useState, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import "moment/locale/es";
import Tarjeta from "../../assets/modalCompartir/card.jpg";
import Mail from "../../assets/modalCompartir/mail2.png";

import axios from "axios";
import PhoneDropDown from "./phoneDropdown";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import useAnalyticsEventTracker from "../../services/useAnalyticsEventTracker";
export const ModalCompartir = ({
  downloadCanvas,
  preRenderImg,
  getUri,
  language,
  showCanvas,
  de,
  para,
  indiceFondo,
}) => {
  const nombreTarjetaIndice = () => {
    switch (indiceFondo) {
      case 1:
        return language.includes("es")
          ? "Fondo Azul Predisenado Espanol"
          : "Fondo Azul Predisenado Ingles";
      case 2:
        return language.includes("es")
          ? "Fondo Crema Predisenado Espanol"
          : "Fondo Crema Predisenado Ingles";
      case 3:
        return language.includes("es")
          ? "Fondo Verde Predisenado Espanol"
          : "Fondo Verde Predisenado Ingles";
      case 4:
        return language.includes("es")
          ? "Fondo Rojo Predisenado Espanol"
          : "Fondo Rojo Predisenado Ingles";
      case 5:
        return language.includes("es")
          ? "Fondo azul para disenar Espanol"
          : "Fondo azul para disenar Ingles ";
      case 6:
        return language.includes("es")
          ? "Fondo crema para disenar espanol"
          : "Fondo crema para disenar ingles";
      case 7:
        return language.includes("es")
          ? "Fondo verde para disenar espanol"
          : "Fondo verde para disenar ingles";
      case 8:
        return language.includes("es")
          ? "Fondo rojo para disenar espanol"
          : "Fondo rojo para disenar ingles";
      default:
        return null;
    }
  };
  const gaEventTracker = useAnalyticsEventTracker("Christmas Card");
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleOnSubmit2 = async () => {
    const response = await fetch(Tarjeta);
    // here image is url/location of image
    const blob = await response.blob();
    const file = new File([blob], "share.jpg", { type: blob.type });
    if (navigator.share) {
      await navigator
        .share({
          title: "title",
          text: "your text",
          url: "url to share",
          files: [file],
        })
        .then(() => console.log("Successful share"))
        .catch((error) => console.log("Error in sharing", error));
    } else {
      console.log(`system does not support sharing files.`);
    }
  };

  return (
    <>
      <Button
        disabled={!showCanvas}
        className="botonLoginP"
        onClick={() => {
          handleShow();
          getUri();
          gaEventTracker(nombreTarjetaIndice());
        }}
      >
        <FormattedMessage id="saveShare" />
      </Button>
      <Modal className="modall" show={show} onHide={handleClose} centered>
        <Modal.Title className="muyBien" centered>
          <Row className="titulo">
            <FormattedMessage id="wellDone" />
          </Row>
        </Modal.Title>
        <Modal.Body>
          <Container>
            <Row>
              <Col xs={12} className="text-center">
                {preRenderImg !== null ? (
                  React.createElement("img", {
                    src: preRenderImg.src,
                    className: "img-fluid img__modal",
                  })
                ) : (
                  <h3 style={{ fontFamily: "NunitoBold", color: "white" }}>
                    <FormattedMessage id="createFirst" />
                  </h3>
                )}
              </Col>
            </Row>
            <Row className="centerContinuar" xs={12} centered>
              <Button className="botonContinuar" onClick={handleClose}>
                {" "}
                <FormattedMessage id="browsing" />
              </Button>
            </Row>
            <Row className="center" style={{ marginTop: "2vh" }} xs={12}>
              <Col className="centerContinuar" xs={6}>
                <Button className="botonGuardar" onClick={downloadCanvas}>
                  <FormattedMessage id="download" />
                </Button>
              </Col>
              <Col
                className="center"
                xs={{ span: 5, offset: 0 }}
                style={{
                  justifyContent: "center",
                  border: "solid 1px white",
                  borderRadius: "25px",
                }}
              >
                <p className="enviarPor">
                  <FormattedMessage id="share" />
                </p>
                {/* <Button className="botonEnviara" onClick={handleOnSubmit2}>
                  {" "}
                  <img
                    src={Mail}
                    className="imagenBotonDentro"
                    alt="logo"
                  ></img>{" "}
                </Button> */}

                <PhoneDropDown
                  url={preRenderImg ? preRenderImg.src : ""}
                  language={language}
                  de={de}
                  para={para}
                />
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};
